// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__frame___3ook6{padding:1rem;text-align:center;min-height:100vh;margin-left:auto;margin-right:auto;max-width:100vw;width:800px}\n", ""]);
// Exports
exports.locals = {
	"frame": "styles__frame___3ook6"
};
module.exports = exports;
