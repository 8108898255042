// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__centre___P-Clg{position:relative;width:100%;display:flex;align-items:stretch;flex-direction:column;justify-content:center;text-align:center;min-height:85vh}\n", ""]);
// Exports
exports.locals = {
	"centre": "styles__centre___P-Clg"
};
module.exports = exports;
