// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__button___127GD{box-sizing:border-box;border:2px solid #00ccbc;padding:1rem;width:100%;background-color:transparent;color:#00ccbc;margin-top:1rem;cursor:pointer}.styles__primary___2xk2l{background-color:#00ccbc;color:#333}\n", ""]);
// Exports
exports.locals = {
	"button": "styles__button___127GD",
	"primary": "styles__primary___2xk2l"
};
module.exports = exports;
