// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__p___1ogw0{padding:1rem}.styles__p___1ogw0 a{color:#00ccbc}.styles__p___1ogw0 a:hover{color:#008f83}\n", ""]);
// Exports
exports.locals = {
	"p": "styles__p___1ogw0"
};
module.exports = exports;
