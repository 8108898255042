// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__example___2VtcZ{padding:1rem;background-color:#ccf3f0;position:relative;margin-bottom:0.5rem}.styles__code___3t6ej{font-size:0.75rem}.styles__code___3t6ej pre{margin-top:-0.5rem !important}.styles__showCode___1DayZ{display:block}.styles__hideCode___1K1cs{display:none}.styles__button___DgNZY{font-size:0.8rem;background:#00ccbc;padding:0.25rem;position:absolute;right:0;bottom:0;border:none}\n", ""]);
// Exports
exports.locals = {
	"example": "styles__example___2VtcZ",
	"code": "styles__code___3t6ej",
	"showCode": "styles__showCode___1DayZ",
	"hideCode": "styles__hideCode___1K1cs",
	"button": "styles__button___DgNZY"
};
module.exports = exports;
